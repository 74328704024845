import React from 'react'
import Movies from '../components/Movies';

const HomePage = () => {
    return (
        <>
            <Movies />
        </>
    )
}

export default HomePage
